@import '~app/mixins';

.dropdownAnalytics {
  position: absolute;
  right: 60px;
  top: 10px;
  z-index: 1000;

  .dropdownButton {
    background: $color-white;
    border: 1px solid $color-filter-border;
    width: 110px;
    height: 28px;
    padding: 5px;
    display: flex;
    align-items: center;
    transition: all $transition-delay;
    border-radius: 100px;
    cursor: pointer;
    justify-content: space-between;
    position: relative;

    &.active:not([disabled]),
    &:hover:not([disabled]) {
      background: $color-light-blue;

      @include primary-border-color();
    }

    &[disabled] {
      background: lighten($color-grey, 45%);
      pointer-events: none;
    }
  }

  .analyticsText {
    color: $color-grey;
    font-size: 12px;
    line-height: 14px;
    padding: 0 3px;
    flex-grow: 1; 
    text-align: center;
  }

  .iconCaretDown,
  .iconAnalytics {
    width: 14px;
    height: 8px;
    position: relative; 
  }
  .iconAnalytics {
    margin-top: -5px
  }

  .dropdown {
    position: absolute;
    right: 0;
    top: 20px;
    max-height: 245px;
    width: 300px;
    border: 1px solid $color-grey;
    opacity: 0.85;
    border-radius: 4px;
    background-color: $color-white;
    cursor: default;
    padding: 0 10px 10px;
    display: none;

    &.open {
      display: block;
    }

    .opacity {
      display: none;
      left: 0;
      top: 45px;
      position: absolute;
      width: 100%;

      $size: 18px;

      .buttonOpacity {
        background: $color-white;
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        transition: all $transition-delay;
        width: $size + 10;
        height: $size + 10;
        position: absolute;

        &.active:not([disabled]),
        &:hover:not([disabled]) {
          background: lighten($color-grey, 50%);
        }

        &[disabled] {
          opacity: 0.5;
          pointer-events: none;
        }

        &.out {
          left: 0;
        }

        &.in {
          right: 0;
        }

        .iconOut,
        .iconIn {
          width: $size;
          height: $size;
        }
      }
    }

    .palette {
      margin-top: 10px;

      .paletteRow {
        display: flex;
        flex-wrap: nowrap;
      }

      .paletteItem {
        height: 20px;
        width: 40px;
      }

      .labels {
        margin-top: 5px;
        color: $color-grey-light;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        justify-content: space-between;
      }
    }

    .buttonsGroup {
      text-align: center;
      display: flex;
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;

      .buttonGroup {
        $regular-color: #66707d;

        flex: 1;
        padding: 5px;
        border: 1px solid $color-grey;
        border-top: none;
        background: $color-white;
        min-width: 40px;
        text-align: center;
        cursor: pointer;
        transition: all $transition-delay;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }

        .label {
          color: $regular-color;
        }

        .iconDollar use,
        .iconHouse use,
        .iconSmallChart use,
        .iconGrowth use {
          fill: $regular-color;
        }

        &.active,
        &:hover {
          background: $color-blue-light;

          .label {
            color: $color-white;
          }

          .iconDollar use,
          .iconHouse use,
          .iconSmallChart use,
          .iconGrowth use {
            fill: $color-white;
          }
        }
      }

      .buttonGroup + .buttonGroup {
        margin-left: -1px;
      }


      .iconDollar {
        width: 9px;
        height: 14px;
      }

      .iconHouse {
        width: 14px;
        height: 14px;
      }

      .iconSmallChart {
        width: 14px;
        height: 14px;
      }

      .iconGrowth {
        width: 20px;
        height: 14px;
      }

      .label {
        font-size: 9px;
        text-align: center;
      }
    }
  }
}

